require ('../../js/plugins/jqueryui');
require ('../../js/plugins/jquery-timepicker');
require ('../../js/plugins/jqueryui-datetimepicker');

$(function () {
  app.DOM.form_input.filter('.datetimepicker').datetimepicker({
    changeMonth: true,
    changeYear: true,
  });

  app.DOM.form_input.filter('input#select_all').click((e) => {
    app.DOM.form_input.filter('input[name^=suppressed_infringements]').prop('checked', e.currentTarget.checked)
  })
})